@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: 0;
}


html, body {
    font-size: 13px;
    font-family: 'Poppins', 'PS Regular', sans-serif !important;
    height: 100%;
    /* overflow-y: scroll; */
    /* -webkit-font-smoothing: antialiased; */

}

button {
    font-family: 'Poppins', 'PS Regular', sans-serif !important;
}

h1.fw-400 {
    font-weight: 400;
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

/* For mobile phones: */
[class*="col-"] {
    margin: inherit 15px;
    padding: 0 1rem !important;
}


.col-1 {
    width: 8.33%;
}

.col-2 {
    width: 16.66%;
}

.col-3 {
    width: 25%;
}

.col-4 {
    width: 33.33%;
}

.col-5 {
    width: 41.66%;
}

.col-6 {
    width: 50%;
}

.col-7 {
    width: 58.33%;
}
.col-8 {
    width: 66.66%;
}

.col-9 {
    width: 75%;
}

.col-10 {
    width: 83.33%;
}

.col-11 {
    width: 91.66%;
}

.col-12 {
    width: 100%;
}

@media only screen and (max-width: 920px) {
    /* For mobile phones: */
    [class*="col-"] {
        margin-top: 15px;
        width: 100%;
    }

    .px-0 {
        padding: 0 .5rem !important;
    }

    .visible-xs {
        display: block !important;
    }

    .hidden-xs {
        display: none !important;
    }
}

.hidden-xs {
    display: block;
}

.visible-xs, .d-none {
    display: none;
}

.row {
    display: flex;
    flex-wrap: wrap;
}


.img-fluid {
    width: 100%;
    height: auto;
}

.text-center {
    text-align: center;
}

a {
    color: inherit;
    text-decoration: none;
}

.loader {
    z-index: 9999;
    position: fixed;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 8em;
    height: 8em;
    border: .7em solid rgba(0, 0, 0, 0.2);
    border-left: .7em solid #b70710;
    border-radius: 50%;
    -webkit-animation: load8 1.1s infinite linear;
            animation: load8 1.1s infinite linear;
    transition: opacity 0.3s;
}

.loader.black {
    z-index: 9999;
    position: fixed;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 8em;
    height: 8em;
    border: .7em solid rgba(0, 0, 0, 0.2);
    border-left: .7em solid rgb(11, 10, 13);
    border-radius: 50%;
    -webkit-animation: load8 1.1s infinite linear;
            animation: load8 1.1s infinite linear;
    transition: opacity 0.3s;
}

.loader-admin {
    position: absolute;
    height: calc(100vh - 70px);
    width: 100%;
    align-items: flex-end;
    justify-content: center;
    align-self: center;
    text-align: center;
    display: flex;
}

.loader-admin > div {

    width: 8em;
    height: 8em;
    border: .7em solid rgba(0, 0, 0, 0.2);
    border-left: .7em solid #b70710;
    border-radius: 50%;
    -webkit-animation: load8 1.1s infinite linear;
            animation: load8 1.1s infinite linear;
    transition: opacity 0.3s;
}

.loader-more {
    width: 3em;
    height: 3em;
    border: .4em solid rgba(0, 0, 0, 0.2);
    border-left: .4em solid #b70710;
    border-radius: 50%;
    -webkit-animation: load8 1.1s infinite linear;
            animation: load8 1.1s infinite linear;
    transition: opacity 0.3s;
    margin: 0 auto;
}

.loader-more.black {
    width: 3em;
    height: 3em;
    border: .4em solid rgba(0, 0, 0, 0.2);
    border-left: .4em solid rgb(11, 10, 13);
    border-radius: 50%;
    -webkit-animation: load8 1.1s infinite linear;
            animation: load8 1.1s infinite linear;
    transition: opacity 0.3s;
    margin: 0 auto;
}

.loader--hide {
    opacity: 0;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}






